import Swiper from "swiper";
export default {
    data() {
        return {
            areaObj: {},
            areaPTZ: {
                solutionId: 999,
                solutionName: " SAAS系统服务方案",
                solutionDescription: "针对产区固定监测点位，进行24小时无间断监测，具备安装便捷、适用范围广的特点，通过固定式云台与云端管控平台结合，提升巡检工作的安全性、及时性和有效性，达到降本增效、减少油储行业企业安全事故的目的!",
                solutionPcImg: require("./images/pc/PTZ.jpg"),
                solutionPhoneImg: require("./images/mobile/PTZ.jpg"),
                webControlSolutionAreaList: [
                    {
                        areaName: "反应单元",
                        areaPcImg: require("./images/pc/p1.jpg"),
                        areaPhoneImg:  require("./images/mobile/p1.jpg"),
                        sort: "1",
                    },
                    {
                        areaName: "门口",
                        areaPcImg: require("./images/pc/p2.jpg"),
                        areaPhoneImg:  require("./images/mobile/p2.jpg"),
                        sort: "1",
                    },
                    {
                        areaName: "给料泵",
                        areaPcImg: require("./images/pc/p3.jpg"),
                        areaPhoneImg:  require("./images/mobile/p3.jpg"),
                        sort: "1",
                    },
                    {
                        areaName: "作业区",
                        areaPcImg: require("./images/pc/p4.jpg"),
                        areaPhoneImg:  require("./images/mobile/p4.jpg"),
                        sort: "1",
                    },
                    {
                        areaName: "液位计",
                        areaPcImg: require("./images/pc/p5.jpg"),
                        areaPhoneImg:  require("./images/mobile/p5.jpg"),
                        sort: "1",
                    },
                    {
                        areaName: "生产单元",
                        areaPcImg: require("./images/pc/p6.jpg"),
                        areaPhoneImg:  require("./images/mobile/p6.jpg"),
                        sort: "1",
                    },


                ],
                webControlSolutionPainList: [
                    {
                        painName: "密集狭小区域",
                        painDescription: "设备密集，空间狭小，巡检人员及轮式巡检机器人较难进入巡检",
                        painPhoneImg: require("./images/mobile/p7.png"),
                        painPcImg: require("./images/pc/p7.png"),
                    }, {
                        painName: "表计识别读数",
                        painDescription: "查看并且记录表计读数、查看识别阀门开闭状态并判断是否异常",
                        painPhoneImg: require("./images/mobile/p8.png"),
                        painPcImg: require("./images/pc/p8.png"),
                    },
                    {
                        painName: "较高检测位置",
                        painDescription: "高处的液位计，检测需要人工登高监看，地面机器人无法巡检该位置，存在巡检盲区",
                        painPhoneImg: require("./images/mobile/p9.png"),
                        painPcImg: require("./images/pc/p9.png"),
                    },
                    {
                        painName: "跑冒滴漏监测",
                        painDescription: "罐区以及管道区域“跑冒滴漏”检测，查看是否有液体泄露",
                        painPhoneImg: require("./images/mobile/p10.png"),
                        painPcImg: require("./images/pc/p10.png"),
                    },
                    {
                        painName: "人员入侵检测",
                        painDescription: "厂区入口或进入作业区入口，可利用现有视频监控资源，实现人员入侵检测",
                        painPhoneImg: require("./images/mobile/p11.png"),
                        painPcImg: require("./images/pc/p11.png"),
                    },
                    {
                        painName: "监控轮巡展示",
                        painDescription: "接入资源较多，需平台具备监控资源轮巡，每个设备包含可见光、红外光及气体传感器三组数据，在平台轮巡展示",
                        painPhoneImg: require("./images/mobile/p12.png"),
                        painPcImg: require("./images/pc/p12.png"),
                    },
                    {
                        painName: "环境气体监测",
                        painDescription: "识别是否有有毒有害气体泄露",
                        painPhoneImg: require("./images/mobile/p13.png"),
                        painPcImg: require("./images/pc/p13.png"),
                    },
                    {
                        painName: "安全行为检测",
                        painDescription: "作业区内，利用现有视频监控资源实现安全行为检测",
                        painPhoneImg: require("./images/mobile/p14.png"),
                        painPcImg: require("./images/pc/p14.png"),
                    },
                ],//痛点问题
                webControlSolutionCaseList: [
                    {
                        solutionCaseName: "采用防爆固定云台搭载气体检测模块覆盖巡检盲区，实现环境气体检测",
                        solutionCaseDescription: null,
                        solutionCasePcImg: require("./images/pc/p15.jpg"),
                        solutionCasePhoneImg: require("./images/mobile/p15.jpg"),
                    },
                    {
                        solutionCaseName: "接入厂区视频监控资源，应用算法实现人员入侵检测",
                        solutionCaseDescription: null,
                        solutionCasePcImg: require("./images/pc/p16.jpg"),
                        solutionCasePhoneImg: require("./images/mobile/p16.jpg"),
                    },
                    {
                        solutionCaseName: "通过红外热成像技术检测设备对设备温度进行检测，也可监测发生超温、剧烈温升的情形",
                        solutionCaseDescription: null,
                        solutionCasePcImg: require("./images/pc/p17.jpg"),
                        solutionCasePhoneImg: require("./images/mobile/p17.jpg"),
                    },
                    {
                        solutionCaseName: "应用人工智能算法，实现人员安全行为检测，监测是否正确穿戴劳动防护用品，是否有抽烟、接打电话等违规行为",
                        solutionCaseDescription: null,
                        solutionCasePcImg: require("./images/pc/p18.jpg"),
                        solutionCasePhoneImg: require("./images/mobile/p18.jpg"),
                    },
                    {
                        solutionCaseName: "应用图像识别功能做，读取表计、跑冒滴漏、液位识别等检测",
                        solutionCaseDescription: null,
                        solutionCasePcImg: require("./images/pc/p19.jpg"),
                        solutionCasePhoneImg: require("./images/mobile/p19.jpg"),
                    },
                    {
                        solutionCaseName: "所有接入设备在机器人管理平台界面展示，监控资源可轮巡",
                        solutionCaseDescription: null,
                        solutionCasePcImg: require("./images/pc/p20.jpg"),
                        solutionCasePhoneImg: require("./images/mobile/p20.jpg"),
                    },
                ],//解决方案
            },
            controlList: [{
                pcImg: require("./images/pc/p21.jpg"),
                phoneImg: require("./images/mobile/p21.jpg"),
                descOne: '数据采集与智能分析',
                descTwo: '机器人管理系统可同时管控机器人与云台，机器人巡检管理系统拥有完全自主知识产权，能与业主方现有的DCS等多种系统、视频监控系统对接整合，并可提供定制功能开发，为客户提供一站式解决方案。'
            },
            {
                pcImg: require("./images/pc/p22.jpg"),
                phoneImg: require("./images/mobile/p22.jpg"),
                descOne: '自主技术与定制服务',
                descTwo: '机器人管理系统自下而上分为数据采集层、数据存储与分析层、数据应用层等多个层面，提供机器人概览、任务查看、历史数据查看、预警查看、结果分析、大数据分析、巡检策略等多项功能，从最小的巡检项到年度数据分析为客户提供全方位巡检监管，从基础出发，用数据体现。'
            },
            {
                pcImg: require("./images/pc/p23.jpg"),
                phoneImg:require("./images/mobile/p23.jpg"),
                descOne: '视频监控与预警处理',
                descTwo: '防爆云台和现有视频监控可提供视频监控轮巡观看界面，支持观看全部设备及在线设备选项，支持预警报警，支持预警结果处理。为客户提供一站式处理能力。'
            },],
            aboutswiper: null,
        }
    },
    mounted() {
        this.$store.dispatch("pageset/getbannerIndex", 0);
        document.documentElement.style.setProperty('--var-primary-color', '#fff');
        this.aboutSwiper();
    },
    // 销毁滚轮事件
    destroyed() {
        // 销毁swiper
        this.aboutswiper.destroy();
    },
    methods: {
        openDetail(areaId) {
            this.$router.push({
                path: "/areaDetail",
                query: {
                    areaId: areaId,
                },
            })
        },
        // 初始化轮播图
        aboutSwiper() {
            let that = this;
            this.aboutswiper = new Swiper('.mySwiper', {
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    renderBullet: function (index, className) {
                        console.log(index,className);
                        if(that.$store.state.pageset.pcshow){
                            return  '<div class="p-bullet ' + className + '"> '+
                            '<p class="p-name">' + (that.controlList[index].descOne) +
                             '</p>' +
                             '<p class="bullet-line"></p>'+
                             '<p class="p-dec">' + (that.controlList[index].descTwo) +
                             '</p>'
                             +'</div>';
                        }else{
                            return  '<span class="p-bullet ' + className + '"> '+
                           '</span>';
                        }
                    },
                },
                autoplay: true,
                speed: 2000,
                loop: true,
                effect: 'fade',//切换效果
                fadeEffect: {
                    crossFade: true  //开启淡出。过渡时，原slide透明度从1->0（淡出），过渡中的slide透明度从0->1（淡入），其他slide透明度0。
                    //false  关闭淡出。过渡时，原slide透明度为1（不淡出），过渡中的slide透明度从0->1（淡入），其他slide透明度0。
                },
            });
        },
    },
}